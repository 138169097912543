import React, { useState, useContext, useEffect } from "react"
import * as userStyles from "./user-synopsis.module.css"
import { i18nContext } from "../provider/i18nprovider";
import { API, graphqlOperation } from 'aws-amplify'

const getUserTenant = `
      query GetUserTenant(
        $tenantId: ID!,
        $userId: ID!
      ) {
        getUserTenant(tenantId: $tenantId, userId: $userId) {
          profileImage
        }
      }
    `;

const UserSynopsis = props => {

  const [employee, setEmployee] = useState(props.employee)
  const [i18n, setI18n] = useContext(i18nContext)
  const [imageState, setImageState] = useState({})
  
  useEffect(() => {
    API.graphql(graphqlOperation(getUserTenant, {tenantId: employee.tenant, userId: employee.owner})).then(userTenant => {
      console.log(userTenant.data.getUserTenant.profileImage)
      setImageState({
        ...imageState,
        image: userTenant.data.getUserTenant.profileImage
      })
    });
  }, [])
  

  var formatter = new Intl.DateTimeFormat("en" , {
    timeStyle: "long",
    dateStyle: "short"
  });

  const alias = i18n[employee.tenant]['eng']['analyticResults'][employee.resultCode] ? i18n[employee.tenant]['eng']['analyticResults'][employee.resultCode].alias : ""

  return (
    <tr className={userStyles.data}>
      <td label="Updated on">{formatter.format(new Date(employee.updatedOn))}</td>
      <td label="Username">{employee.owner}</td>
      <td label="Result">{alias}</td>
      <td label="Reporter">{employee.reporters.join(', ')}</td>
      <td><img style={{margin: "0px", maxWidth: "100px"}} src={employee.facial} alt="loading..." /></td>
      <td><img style={{margin: "0px", maxWidth: "100px"}} src={"data:image/jpeg;base64," + imageState.image} alt="loading..." /></td>
      <td></td>
    </tr>
  )

}

export default UserSynopsis