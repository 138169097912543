import React, { useState, useEffect, useContext } from "react"
import { API, graphqlOperation } from 'aws-amplify'
import UserSearchResults from "./user-search-results"
import SearchForm from "./search-form"
// import LoadingOverlay from 'react-loading-overlay'
import Paging from "./paging"
import { UserTenantContext } from "../provider/usertenantprovider"
import * as searchStyles from "./search.module.css"
import { InternationalizationProvider } from "../provider/i18nprovider"

const searchQuestionnairesFlat = `
    query SearchQuestionnaires(
        $filter: SearchableQuestionnaireFilterInput
        $sort: SearchableQuestionnaireSortInput
        $limit: Int
        $nextToken: String
      ) {
        searchQuestionnaires(
          filter: $filter
          sort: $sort
          limit: $limit
          nextToken: $nextToken
        ) {
          items {
            id
            reporters
            owner
            screeningDate
            updatedOn
            resultCode
            tenant
            facial
          }
          nextToken
          total
        }
      }
    `;
const Search = () => {

    const [searchState, setSearchState] = useState({results: [],tokenStack: []})
    const [isLoading, setIsLoading] = useState(false)
    const [tenantList, setTenantList] = useContext(UserTenantContext);

    const search = async () => {
        setIsLoading(true)

        const nextToken = searchState.currentToken;

        let filter = null;

        const startDate = searchState.startDate;
        const endDate = searchState.endDate;
        const tenant = searchState.tenant;
        const user = searchState.user;
        const reporter = searchState.reporter;
        const resultCode = searchState.resultCode;

        filter = { and: {}};

        if(startDate != undefined || endDate != undefined) {
            filter.and.updatedOn = {};

            if(startDate != undefined) {
              let startOfDay = new Date(startDate)
              startOfDay.setHours(0, 0, 0, 0)
              filter.and.updatedOn.gte = startOfDay.toISOString();
          }

          if(!endDate){
            let endOfDay = new Date(startDate)
              endOfDay.setDate(endOfDay.getDate() + 1)
              endOfDay.setHours(0, 0, 0, 0)
              filter.and.updatedOn.lte = endOfDay.toISOString();
          }
           else {
              let endOfDay = new Date(endDate)
              endOfDay.setDate(endDate.getDate() + 1)
              endOfDay.setHours(0, 0, 0, 0)
              filter.and.updatedOn.lte = endOfDay.toISOString();
          }
        }

        if(tenant != undefined) {
            filter.and.tenant = {};
            filter.and.tenant.eq = tenant.value;
        } else {
            filter.and.or = []
            tenantList.forEach((i) => { 
                filter.and.or.push({tenant:{eq:i.tenantId}}) 
            })
        }
        if(user != undefined && user != null && user != "") {
            filter.and.owner = {};
            filter.and.owner.eq = user
        }
        if(reporter != undefined && reporter != null && reporter != "") {
            filter.and.reporters = {};
            filter.and.reporters.eq = reporter
        }
        if(resultCode != undefined && resultCode != null && resultCode != "") {
            filter.and.resultCode = {};
            filter.and.resultCode.eq = resultCode
        }

        var result = await API.graphql(graphqlOperation(searchQuestionnairesFlat, {filter: filter, limit: 20, nextToken: nextToken, sort: {field:"screeningDate", direction:"desc"}}));

        setSearchState({
            ...searchState,
            tokenStack: [...searchState.tokenStack, result.data.searchQuestionnaires.nextToken],
            results: result.data.searchQuestionnaires.items
        });
        setIsLoading(false)
    }

    const changeHandler = (startDate, endDate, tenant, resultCode, user, reporter) => {
        setSearchState({currentToken: null, results: [], startDate: startDate, endDate: endDate, tokenStack: [], tenant: tenant, resultCode: resultCode, user: user, reporter: reporter, id: new Date().getTime()});
    }

    useEffect(() => {
        if(searchState.startDate || searchState.endDate || searchState.tenant|| searchState.resultCode || searchState.user || searchState.reporter) {
            search()
        }
    }, [searchState.currentToken, searchState.id])

    const previousPage = () => {
        const currentToken = searchState.tokenStack[searchState.tokenStack.length - 3]
        setSearchState({
            ...searchState,
            tokenStack: searchState.tokenStack.splice(0, searchState.tokenStack.length - 2),
            currentToken: currentToken
            }
        )
    }

    const nextPage = () => {
        setSearchState({
            ...searchState,
            currentToken: searchState.tokenStack[searchState.tokenStack.length - 1]
        })
    }

    const previousEnabled = searchState.tokenStack.length > 1;
    const nextEnabled = searchState.tokenStack[searchState.tokenStack.length - 1] != null;

    return (
        <>
            {/* <LoadingOverlay active={isLoading} spinner> */}
                <InternationalizationProvider>
                    <h1>Employees</h1>
                    <div className={searchStyles.flex}>
                        <div className={searchStyles.formWrapper}>
                            <SearchForm changeHandler={changeHandler} />
                        </div>
                        <div className={searchStyles.resultsWrapper}>
                            <UserSearchResults results={searchState.results} isLoading={false} />
                            <Paging onNextClick={nextPage} onPreviousClick={previousPage} previousEnabled={previousEnabled} nextEnabled={nextEnabled}/>
                        </div>
                    </div>
                </InternationalizationProvider>
            {/* </LoadingOverlay> */}
        </>
    );
      
}
export default Search