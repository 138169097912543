import React, { useState, createContext, useContext, useEffect } from "react"
import { API, graphqlOperation } from 'aws-amplify'
import { UserContext } from "./usercontextprovider"


const listUserTenants = /* GraphQL */ `
  query ListUserTenants(
    $userId: ID
    $filter: ModelUserTenantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserTenants(userId: $userId, filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        tenantId
        tenantObj {
          name
          codeTranslations {
            items {
              language
              questions {
                id
                question
              }
              analyticResults {
                id
                alias
                answer
                proceed
              }
            }
          }
        }
      }
      nextToken
    }
  }
`

export const i18nContext = createContext([{}, () => {}])
// Selected Language?
export const LanguageContext = createContext([
  {},
  ()=>{}
])

export const InternationalizationProvider = props => {
  const [i18n, setI18n] = useState({})
  const languageStateHook = useState({language:"eng"});
  // const [userTenantContext, setUserTenantContext] = useContext(UserTenantContext)
  const [userContext, setUserContext] = useContext(UserContext);


  useEffect(() => {
    const fetchData = async ()=>{
      let tenantMap = {}
      let result = await API.graphql(
        graphqlOperation(listUserTenants, {
          userId: userContext.username
        })
      )
      result.data.listUserTenants.items.forEach(userTenant=>{
          let tenant = userTenant.tenantObj
          tenantMap[tenant.name] = {}
          tenant.codeTranslations.items.forEach(translation =>{
            let tenantResultCodes = {}
            let tenantQuestions = {}
              translation.questions.forEach(question => {
                tenantQuestions[question.id] = question
              })
              translation.analyticResults.forEach(analyticResult => {
                tenantResultCodes[analyticResult.id] = analyticResult
            })
            tenantMap[tenant.name][translation.language] = {
              analyticResults: tenantResultCodes,
              questions: tenantQuestions
            }
          })
      });
      setI18n(tenantMap)
    };
    if(userContext.username){
      fetchData()
    }
  }, [userContext.username])

  return (
    <i18nContext.Provider value={[i18n, setI18n]}>
      <LanguageContext.Provider value={languageStateHook}>
        {props.children}
      </LanguageContext.Provider>
    </i18nContext.Provider>
  )
}
