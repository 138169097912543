import React from "react"
import UserSynopsis from "./user-synopsis"
import * as tableStyles from "./user-search-results.module.css"

class UserSearchResults extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    const { results } = this.props;

    return (
      <div className={tableStyles.table}>
        <table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Username</th>
              <th>Result</th>
              <th>Reporter</th>
              <th>Submitted Photo</th>
              <th>Profile Photo</th>
            </tr>
          </thead>
          <tbody>
            {
              results.map(employee =>
                <UserSynopsis key={employee.id} employee={employee} />
              )
            }
          </tbody>
        </table>
      </div>
    )
  }

}
export default UserSearchResults