import React, { useContext } from "react"
import SEO from "../../components/seo"
import Search from "../../components/search"
import CustomAuth from "../../components/custom-auth"
import { UserContext } from "../../provider/usercontextprovider"
import { UserTenantProvider } from "../../provider/usertenantprovider"

const Results = ({ location }) => {
  const [userContext, setUserContext] = useContext(UserContext);
  const isManager = userContext.username && userContext.signInUserSession.accessToken.payload["cognito:groups"].includes('Manager');

  return (
    <CustomAuth>
      <SEO title="Search" />
        <UserTenantProvider>
          {isManager ? <Search /> : <p>User is not a manager</p>}
        </UserTenantProvider>
    </CustomAuth>
  )

}

export default Results
