import React, { useContext } from "react"
import { useState } from 'react'
import * as buttonStyles from './button.module.css'
import * as searchFormStyles from './search-form.module.css'
import Select from 'react-select'
import { UserTenantContext } from "../provider/usertenantprovider"
import DayPicker, { DateUtils } from 'react-day-picker'
import 'react-day-picker/lib/style.css'
import { i18nContext } from "../provider/i18nprovider";

const SearchForm = (props) => {
  const [showSearch, setShowSearch] = useState(true);
  const [tenant, setTenant] = useState(null);
  const [user, setUser] = useState([
    null
  ]);
  const [reporter, setReporter] = useState([
    null
  ]);
  const [resultCode, setResultCode] = useState([
    null
  ]);
  const [tenantList, setTenantList] = useContext(UserTenantContext);
  const tenantOptions = tenantList.filter(t => t.tenantRole.includes("Manager")).map((tenant) => {
    return {
      value: tenant.tenantObj.name,
      label: tenant.tenantObj.prettyName
    }
  })
  const [resultCodeOptions, setResultCodeOptions] = useState([])
  const [i18n, setI18n] = useContext(i18nContext)
  const numberOfMonths = 1;
  let [from, setFromDate] = useState(undefined);
  let [to, setToDate] = useState(undefined);

  const handleDayClick = (day) => {
    let range = DateUtils.addDayToRange(day, { to, from });
    setFromDate(range.from);
    setToDate(range.to);
  };

  const toggleSearchFilter = (e) => {
    setShowSearch(!showSearch);
  }

  const callback = () => {
    props.changeHandler(from, to, tenant, resultCode, user, reporter);
  };
  const onTenantChange = (e) => {
    setTenant(e);
    let resultCodes = []
    const analyticResults = i18n[e.value]['eng']['analyticResults'];
    for(var key of Object.keys(analyticResults)) {
      resultCodes.push({
        value: key,
        label: analyticResults[key].alias
      })
    }
    setResultCodeOptions(resultCodes)
    console.log(e);
  };
  return (
    <div className={searchFormStyles.formContainer}>
      <div className={searchFormStyles.section}>
        <h2 className={searchFormStyles.formHeader} onClick={(e) => toggleSearchFilter(e.target)}>
          Search filter
          <svg className={searchFormStyles.filter} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z" />
          </svg>
        </h2>
        <div className={searchFormStyles.formWrapper} style={{ display: showSearch == true ? 'block' : 'none' }}>
          <Select options={tenantOptions} onChange={onTenantChange} placeholder="Select Tenant Name" />
          <span className={searchFormStyles.message} style={{ display: tenant == null ? 'block' : 'none' }}>Please select a Tenant</span>
          <Select className={searchFormStyles.select} options={resultCodeOptions} placeholder="Select Result Code" isDisabled={!tenant ? "disabled" : null} onChange={(e) => setResultCode(e.value)} />
          <input className={searchFormStyles.input} name="reporter" type="text" placeholder="Search Reporter" onChange={(e) => setReporter(e.target.value)} />
          <input className={searchFormStyles.input} name="user" type="text" placeholder="Search User" onChange={(e) => setUser(e.target.value)} />
          <div className={searchFormStyles.calendar}>
            <DayPicker
              className="Selectable"
              modifiers={{ start: from, end: to }}
              numberOfMonths={numberOfMonths}
              selectedDays={[from, { from, to }]}
              onDayClick={handleDayClick}
            />
            <style>
              {`
              .Selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
                background-color: #f0f8ff !important;
                color: #4a90e2;
              }
              .Selectable .DayPicker-Day {
                border-radius: 0 !important;
              }
              .Selectable .DayPicker-Day--start {
                border-top-left-radius: 50% !important;
                border-bottom-left-radius: 50% !important;
              }
              .Selectable .DayPicker-Day--end {
                border-top-right-radius: 50% !important;
                border-bottom-right-radius: 50% !important;
              }
            `}
            </style>
          </div>
          <div>
            <button className={buttonStyles.button} onClick={callback}>Search</button>
          </div>
        </div>
      </div>
    </div>
  )

}
export default SearchForm